html,
body {
  overflow-x: hidden;
}

.cl-rootBox > .cl-card > div:last-child[class^="cl-internal-"] {
  display: none;
}

.cl-userButtonPopoverRootBox > .cl-userButtonPopoverCard > .cl-userButtonPopoverFooter:last-child {
  display: none;
}

.main-content {
  min-height: 400px;
  padding: 40px;
}

@media (max-width: $mantine-breakpoint-sm) {
  .main-content {
    padding: 20px 0;
  }
}

/* TalkJS overrides */

.__talkjs_popup {
  right: 15px !important;
  bottom: 15px !important;
}

/* Blog overrides for Mailchimp styles */

/* We use elements in the selectors (td, table, etc.) for more specificity */

/* Hide Mailchimp blog posts header and footer */
td#templatePreheader,
td#templateFooter,
td.bodyContainer > table.mcnImageBlock:first-child {
  display: none !important;
}

/* Remove Mailchimp blog posts background color so it blends perfectly with our own background */
table#bodyTable,
td#templateHeader,
td#templateBody,
td#templateColumns {
  background: initial !important;
}

/* Remove Mailchimp blog posts last separator line right before our subscribe box */
td#templateColumns {
  border-bottom: none !important;
}
